import React from 'react';  
import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";  


import Select from 'react-select';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

const Navegacion = () => {
  const location = useLocation();  
  const [token, setToken] = useState(sessionStorage.getItem('_token'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem('token'));
  const [MiUsuario, setMiUsuario] = useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
  const [casas, setCasas]=useState([
    {value:1, label:'Casa 1'},
    {value:2, label:'Casa 2'},
    {value:3, label:'Casa 3'},
    {value:4, label:'Casa 4'},
    {value:5, label:'Casa 5'},
    {value:6, label:'Casa 6'},
    {value:7, label:'Casa 7'},
    {value:8, label:'Casa 8'},
    {value:9, label:'Casa 9'},
    {value:10, label:'Casa 10'},
    {value:11, label:'Casa 11'},
    {value:12, label:'Casa 12'},
    {value:13, label:'Casa 13'},
    {value:14, label:'Casa 14'},
    {value:15, label:'Casa 15'},
    {value:16, label:'Casa 16'},
    {value:17, label:'Casa 17'},
    {value:18, label:'Casa 18'},
    {value:19, label:'Casa 19'},
    {value:20, label:'Casa 20'},
    {value:21, label:'Casa 21'},
    {value:22, label:'Casa 22'},
    {value:23, label:'Casa 23'},
    {value:24, label:'Casa 24'},
    {value:25, label:'Casa 25'},
    {value:26, label:'Casa 26'},
    {value:27, label:'Casa 27'},
    {value:28, label:'Casa 28'},
    {value:29, label:'Casa 29'},
    {value:30, label:'Casa 30'},
    {value:31, label:'Casa 31'},
    {value:32, label:'Casa 32'},
    {value:33, label:'Casa 33'},
    {value:34, label:'Casa 34'}

]);
  const navigate = useNavigate();

  const sessionDuration = 8 * 60 * 60 * 1000; 

  const cambiarCasa=(event)=>{            
    MiUsuario.casa=event.value    
    sessionStorage.setItem('_MiUsuario',JSON.stringify(MiUsuario))  
    setMiUsuario(JSON.parse(sessionStorage.getItem('_MiUsuario'))) 
    navigate('/recibos');
    window.location.reload();
  }


  const checkSessionExpiration = () => {
    const token = sessionStorage.getItem('token');
    const loginTime = sessionStorage.getItem('loginTime');
    const now = new Date().getTime();

    if (token && loginTime && now - loginTime > sessionDuration) {
      // Si ha pasado el tiempo límite, elimina el token y redirige al login
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('loginTime');
      setIsAuthenticated(false);
      navigate('/login');
    }
  };



  
  useEffect(() => {  
    console.info(window.hash)

    if (!token) {    
      navigate('/login');
    }    
    const interval = setInterval(checkSessionExpiration, 60000); 
    return () => clearInterval(interval); 
  }, [navigate]);


  const user = {
    name: MiUsuario ? MiUsuario.nombre : "",
    email: MiUsuario ? MiUsuario.correo : "",
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  let navigation = [];
  if (MiUsuario){
      if (MiUsuario.idperfil == 99) {
        navigation = [
          { name: 'Inicio', href: '/', current: window.location.hash=='#/' ? true : false },
          { name: 'Recibos', href: 'recibos', current: window.location.hash=='#/recibos' ? true : false  },
          { name: 'Gastos', href: 'gastos', current: window.location.hash=='#/gastos' ? true : false  },
          { name: 'Adeudos', href: 'adeudos', current: window.location.hash=='#/adeudos' ? true:false  },
          { name: 'Registrar Pago', href: 'registrarPago', current: window.location.hash=='#/registrarPago' ? true:false  },
          { name: 'Registrar Gasto', href: 'registrarGasto', current: window.location.hash=='#/registrarGasto' ? true:false  },
        ]
      } else {
        navigation = [          
          { name: 'Inicio', href: '/', current: window.location.hash=='#/' ? true : false },
          { name: 'Recibos', href: 'recibos', current: window.location.hash=='#/recibos' ? true : false  },
          { name: 'Gastos', href: 'gastos', current: window.location.hash=='#/gastos' ? true : false  },
          { name: 'Adeudos', href: 'adeudos', current: window.location.hash=='#/adeudos' ? true:false  },
        ]
      }
  }

  const userNavigation = [
    { name: 'Salir', href: 'salir' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
    {MiUsuario &&  MiUsuario.idperfil && 
      <Disclosure as="nav" className="fixed w-full border-b border-green-400 border-opacity-25 bg-gray-900 lg:border-none m-auto">
        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
          <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-gray-400 lg:border-opacity-25">
            <div className="flex items-center px-2 lg:px-0">
              <div className="hidden lg:ml-10 lg:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current
                          ? 'bg-green-600 text-white'
                          : 'text-white hover:bg-green-500 hover:bg-opacity-75',
                        'rounded-md px-3 py-2 text-sm font-medium',
                      )}
                    >
                      {item.name}
                    </NavLink>
                  ))}
          

                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">

            </div>
            <div className="flex lg:hidden z-40">
              {/* Mobile menu button */}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-green-200 hover:bg-gray-900 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
              </DisclosureButton>
            </div>
            <div className="hidden lg:ml-6 lg:block">
              <div className="flex items-center">
                <button
                  type="button"
                  className="relative flex-shrink-0 rounded-full bg-gray-900 p-1 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only"></span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Profile dropdown */}
                {MiUsuario.idperfil==99 && 
                  <Select options={casas} className="basic-multi-select z-50 absolute w-full" classNamePrefix="select" placeholder="Casa" onChange={cambiarCasa} />
                }
                <Menu as="div" className="relative ml-3 flex-shrink-0">

                  <div>
                    <MenuButton className="relative flex rounded-full bg-gray-900 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only"></span>
                      
                      <span className='p-2'>{user.name}</span>
                      {/* <img alt="" src={user.imageUrl} className="h-8 w-8 rounded-full" /> */}
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-green-300 text-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        <NavLink
                          to={item.href}
                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                        >
                          {item.name}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel className="lg:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                as="a"
                to={item.href}
                aria-current={item.current ? 'page' : undefined}
                className={classNames(
                  item.current ? 'bg-green-700 text-white' : 'text-white hover:bg-gray-900 hover:bg-opacity-75',
                  'block rounded-md px-3 py-2 text-base font-medium',
                )}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <div className="border-t border-green-700 pb-3 pt-4">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                {/* <img alt="" src={user.imageUrl} className="h-10 w-10 rounded-full" /> */}
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-white">{user.name}</div>
                <div className="text-sm font-medium text-green-300">{user.email}</div>
                {MiUsuario.idperfil==99 && 
                  <Select options={casas} className="w-full basic-multi-select z-50 absolute" classNamePrefix="select" placeholder="Casa" onChange={cambiarCasa} />
                }
              </div>
              <button
                type="button"
                className="relative ml-auto flex-shrink-0 rounded-full bg-gray-900 p-1 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">View notifications</span>
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-3 space-y-1 px-2">
              {userNavigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-900 hover:bg-opacity-75"
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
    }
    </>
  )
}

export default Navegacion;