import React from 'react';  
import { Outlet } from "react-router-dom"; 
import Navegacion from "./Navegacion";
const LayoutHome=()=>{
  return (
    <>      
      <div className="min-h-full">
        <div className="bg-gray-900 pb-32">
         <Navegacion/>
          <header className="py-5">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Villas Fontana II</h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-screen">
              <Outlet/>  
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
export default LayoutHome;