import React from 'react';  
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import Cargador from '../dasboard/Cargador'
import {formatoMexico, } from '../helpers/tools';
import { useNavigate } from "react-router-dom";

const Adeudos=()=>{
    const [token, setToken]=useState(sessionStorage.getItem('_token'));    
    const [MiUsuario, setMiUsuario]=useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
    const [loading, setLoading]=useState(false);

    const [adeudos , setAdeudos]=useState([])
    const [detalle , setDetalle]=useState([])
    const [mi_AdeudoTotal, setMi_AdeudoTotal]=useState(0)
    const [adeudosCerrada , setAdeudosCerrada]=useState('')
    
    const navigate = useNavigate();

    useEffect(()=>{                                                
        consultarAdeudos();    
    },[]);

        
      const consultarAdeudos=async()=>{        
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
   
        if(token){
            const datos = {
                "casa":MiUsuario.casa
            }     
            let valores=[]          
            setLoading(true)  
            let valoresGenerales=[] 
            let valoresPorPagar=[] 
            let detalle=[] 
               await clienteAxios.post(`/api/recibos/adeudos`, datos, config)
                .then(res=>{                    
                    
                    if(res.data.estatus==401){
                        if (typeof window !== 'undefined') {
                            navigate('/salir');     
                            window.location.reload();  
                          }           
                    }
                    
                    res.data.data.mis_adeudos.forEach(element => {                                      
                       const {mes, anio, cuota}=element
                       let dato={mes, anio, cuota}            
                        valores=[...valores, dato]                 
                    });  
                    res.data.data.porpagar.forEach(element => {                                      
                        const {id, casa, mes , anio, cuota, pago}=element
                        let dato={id, casa, mes , anio, cuota, pago}            
                        detalle=[...detalle, dato]                 
                     });                      
                    res.data.data.topCasas.forEach(element => {                                      
                        const {casa, total}=element
                        let dato={casa, total}            
                        valoresGenerales=[...valoresGenerales, dato]                 
                     });                 
                    let mi_adeudo=res.data.data.total_adeudo[0].total                                         
                    setMi_AdeudoTotal(mi_adeudo)                          
                    setDetalle(detalle)                    
                    setLoading(false) 
                }).catch(err=>{
                    setLoading(false) 
                    console.log(err)
                });                                             
               
                setAdeudos(valores)                              
                setAdeudosCerrada(valoresGenerales)                 
        }
    }
  return (
    <>
    {loading && <Cargador/>}
    <h1 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 my-2' >Adeudos </h1>
    
    <h1 className='text-start font-bold text-lg w-full bg-slate-400 p-4' >Adeudo General  </h1>        
    <table className='table-auto ms-10 w-4 mt-3'>
<tr>
    <th className='font-bold uppercase w-full text-md'>Casa</th>
    <th className='font-bold uppercase w-full text-md'>Monto</th>        
</tr>
{adeudosCerrada && adeudosCerrada.map((dato, index)=>(                                                            
        <tr className=''>
            <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.casa}</span></td>            
            <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold text-red-600'>${formatoMexico(dato.total)}</span></td>                        
        </tr>                                                                                                     
    ))}
    </table>

    <h1 className='text-start font-bold text-lg w-full bg-slate-400 p-4' >Mi adeudo total es por: <span className='text-red-600'>${formatoMexico(mi_AdeudoTotal)}.00</span> </h1>
    <h1 className='text-start font-bold text-lg mt-5' >Detalle: </h1>
    <table className='table-auto ms-10 w-4 mt-3'>
    <tr>
        <th className='font-bold uppercase w-full text-md'>Año</th>
        <th className='font-bold uppercase w-full text-md'>Mes</th>
        <th className='font-bold uppercase w-full text-md'>Adeudo</th>
    </tr>
    {adeudos && adeudos.map((dato, index)=>(                                                            
          <tr className=''>
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-normal'>{dato.anio}</span></td>            
                <td className='font-bold uppercase w-full whitespace-nowrap text-md px-4 bg-white rounded-lg p-2 m-1'><span className='font-normal'>{dato.mes}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className=' text-red-600'>${formatoMexico(dato.cuota)}.00</span></td>                            
          </tr>                 
      ))}
    </table>

      <h1 className='text-start font-bold text-lg w-full bg-slate-400 p-4' >Detalle de Pagos  </h1>        
    <table className='table-auto ms-10 w-4 mt-3'>
    <tr>        
        <th className='font-bold uppercase w-full text-md'>Casa</th>        
        <th className='font-bold uppercase w-full text-md'>Año</th>        
        <th className='font-bold uppercase w-full text-md'>Mes</th>        
        <th className='font-bold uppercase w-full text-md'>Cuota</th>        
        <th className='font-bold uppercase w-full text-md'>Pago</th>        
    </tr>
    {detalle && detalle.map((dato, index)=>(                                                            
          <tr className=''>                
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.casa}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.anio}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.mes}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold text-black'>${formatoMexico(dato.cuota)}</span></td>                        
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className={dato.pago<dato.cuota ? 'text-red-600': 'text-green-600'}>${formatoMexico(dato.pago)}</span></td>                        
          </tr>                                                                                                     
      ))}
    </table>

    
    </>
  )
}


export default Adeudos;