import React from 'react';  
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import Password from "../componentes/Password";
import Celular from "../componentes/Celular";
import MensajeError from '../componentes/MensajeError';
import {useNavigate} from "react-router-dom";

import {Encrypt} from '../helpers/tools'
const Login=({
  
})=>{
    const [celular,setCelular]=useState('');
    const [clave,setClave]=useState('');
    const [userEmail, setEmail]=useState("");

    const [isLogin, setIslogin]=useState(false);
    const [token, setToken]=useState('');  
    const [MiUsuario, setMiUsuario]=useState("");
    const [msgError, setMesgError]=useState("");

    const navigate = useNavigate();


    useEffect(() => {          
      if (token) {    
        navigate('/');
      }   
    })

    const IniciarSesion= async(event)=>{
      event.preventDefault();          
      if( [celular, clave].includes('')){
          setMesgError("Debe ingresar todos los datos.")
      }else{
      const data ={   
          "op":"login",         
          "celular":celular,          
          "clave":Encrypt(clave)
          // "clave":clave
        }
        const config = {
          headers: {
              "Content-Type": "application/json",      
          }        
        }            
        try{   
          const datas =await clienteAxios.post('api/usuarios/login', data, config)            
          let {acceso, token, correo, nombre, celular, casa, activo, idperfil, perfil}=datas.data   
          if(activo===0){
            setIslogin(false);
            setMesgError(datas.data.mensaje)
          }else if(acceso===1 && token){                               
              setToken(token);
              setMiUsuario(datas.data)
              setMesgError('')                 
              sessionStorage.setItem('_token',token)
              sessionStorage.setItem('_MiUsuario',JSON.stringify(datas.data))                                 
              navigate('/recibos');
              window.location.reload();
          }else{
              setIslogin(false);
              setMesgError(datas.data.mensaje)
          }
        }catch(err){          
          setIslogin(false);         
          setMesgError("Datos de acceso incorrectos!!!")
        }
       
      }
  }

     return (
    <>       
 
      
      <div className="mx-auto max-w-2xl text-left">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                alt="Your Company"
                src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                className="h-10 w-auto"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center">
              Inicia sesión en tu cuenta
              </h2>
            
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={IniciarSesion} method="POST" className="space-y-6">
                  <div>                   
                    <div className="mt-2">
                      <Celular valor={celular} setValue={setCelular}  requerido="true"   />     
                    </div>
                  </div>

                  <div>                 
                    <div className="mt-2">                      
                      <Password  valor={clave} setValue={setClave} requerido="true"/>    
                    </div>
                  </div>

                  {/* <div className="flex items-center justify-between">                  
                    <div className="text-sm leading-6">
                      <a href="#" className="font-semibold text-green-600 hover:text-green-500">
                       Olvide mi contraseña
                      </a>
                    </div>
                  </div> */}

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                     Ingresar
                    </button>
                  </div>
                </form>
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                  </div>
              
                </div>

                <MensajeError msgError={msgError} /> 
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </>
  )
}

export default Login;