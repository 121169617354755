import React from 'react';  
const NotFoundPage=()=>{

    return(
        <>
        <h1 className=" text-center text-pink-700 text-2xl">Parece que estas en una sección que no existe.</h1>
        </>
    )
}

export default NotFoundPage;