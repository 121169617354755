let CryptoJS = require("crypto-js");

export const formatearFecha=fecha=>{
    const fechaNueva= new Date(fecha)
    const opciones={
      year:'numeric',
      month:'long',
      day:'2-digit'
    }
    return fechaNueva.toLocaleDateString('es-ES',opciones)
  }
export const formatoMexico = (number=0) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp,rep);
  }

  
export const Encrypt=(dato)=>{       
    let valor=CryptoJS.AES.encrypt(dato, 'controlC4hunters').toString()    
    return  valor;
}