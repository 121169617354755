import React from 'react';  
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import Select from 'react-select';
import MensajeOK from '../componentes/MensajeOk'
import MensajeError from '../componentes/MensajeError'; 

const RegistrarPago=()=>{
    const [token, setToken]=useState(sessionStorage.getItem('_token'));    
    const [MiUsuario, setMiUsuario]=useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
    const [loading,setLoading]=useState(false);

    const [fecha, setFecha]=useState("");
    const [concepto, setConcepto]=useState("");
    const [monto, setMonto]=useState(0);
    const [medios, setMedios]=useState([
        {value:"Transferencia", label:"Transferencia"},
        {value:"Efectivo", label:"Efectivo"}
    ]);
    const [recibo, setRecibo]=useState("");
    const [observaciones, setObservaciones]=useState("");
    const [recivos, setRecibos]=useState([]);
    const [adeudosPorPagar, setAdeudosPorPagar]=useState([])
    const [periodos, setPeriodos]=useState([]);
    const [casa, setCasa]=useState(0);
    const [casas, setCasas]=useState([
        {value:1, label:'Casa 1'},
        {value:2, label:'Casa 2'},
        {value:3, label:'Casa 3'},
        {value:4, label:'Casa 4'},
        {value:5, label:'Casa 5'},
        {value:6, label:'Casa 6'},
        {value:7, label:'Casa 7'},
        {value:8, label:'Casa 8'},
        {value:9, label:'Casa 9'},
        {value:10, label:'Casa 10'},
        {value:11, label:'Casa 11'},
        {value:12, label:'Casa 12'},
        {value:13, label:'Casa 13'},
        {value:14, label:'Casa 14'},
        {value:15, label:'Casa 15'},
        {value:16, label:'Casa 16'},
        {value:17, label:'Casa 17'},
        {value:18, label:'Casa 18'},
        {value:19, label:'Casa 19'},
        {value:20, label:'Casa 20'},
        {value:21, label:'Casa 21'},
        {value:22, label:'Casa 22'},
        {value:23, label:'Casa 23'},
        {value:24, label:'Casa 24'},
        {value:25, label:'Casa 25'},
        {value:26, label:'Casa 26'},
        {value:27, label:'Casa 27'},
        {value:28, label:'Casa 28'},
        {value:29, label:'Casa 29'},
        {value:30, label:'Casa 30'},
        {value:31, label:'Casa 31'},
        {value:32, label:'Casa 32'},
        {value:33, label:'Casa 33'},
        {value:34, label:'Casa 34'}

    ]);
    const [idperfil, setIDPerfil]=useState(0);
    const [medio, setMedio]=useState();
    const [msgError, setMesgError]=useState("");

    const msgOk="Se registró correctamente el recibo";
     
   const handleChangeMedio=(event)=>{
    setMedio(event.value)
   }

   const handleChangeCasa=(event)=>{    
    setCasa(event.value);
    consultarAdeudos(event.value);
   }


    const consultarAdeudos=async(casa)=>{        
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }

    if(token && casa){
        const datos = {
            "casa":casa
        }              
        let valoresPorPagar=[]           
            await clienteAxios.post(`api/recibos/adeudos`, datos, config)
            .then(res=>{                                                                                                                             
                res.data.data.porpagar.forEach(element => {                                      
                    const {id, periodo}=element
                    let dato={value:id, label: periodo}            
                    valoresPorPagar=[...valoresPorPagar, dato]                 
                    });                                  
                setAdeudosPorPagar(valoresPorPagar)                
            }).catch(err=>{
                console.log(err)
                alert(err.message)
            });                                             
            
                
                        
    }
    }
    
    const ConceptoDePago=(item)=>{
        setPeriodos(item)       
        let periodos='Mantenimiento: ';
        item.forEach(x=>{        
        periodos+=x.label+'.'
        })      
        setConcepto(periodos)
    }
     
    useEffect(()=>{    
        setCasa(setMiUsuario.casa)
        setIDPerfil(setMiUsuario.idperfil)             
    },[]) 
    
      
      
      
      
      const RegistrarPago=async(event)=>{
        event.preventDefault(); 

        if( [casa, fecha, concepto, monto, medio].includes('')){
            setMesgError("Debe ingresar todos los datos.");
            return;
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }       
      if(token){      
          const datos = {
              "op":"registro",    
              "casa":casa,
              "fecha":fecha,
              "concepto":concepto,
              "monto":monto,
              "medio":medio,
              "observaciones":observaciones,
              "periodos":periodos,              
          }        
          let valores=[]           
             await clienteAxios.post(`api/recibos/`, datos, config)
              .then(res=>{                
                setRecibo(res.data.data[0].recibo)
                const interval = setInterval(() => {
                //   limpiar()             
                }, 3000);
              })
            }
      }
    return(
        <>
        <form onSubmit={RegistrarPago}>  
                <div className='div-control p-3'>
                    {!recibo == '' && <MensajeOK msgOk={msgOk} />}
                    <h1 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 my-2'>Registro de Pago <span>{recibo}</span></h1>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Fecha</label>
                    <input type='date' className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "  onChange={({target:{value}})=>setFecha(value)} required />
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Casa</label>
                    <Select options={casas} className="basic-multi-select" classNamePrefix="select"  onChange={handleChangeCasa} required/>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Periodo(s)</label>
                    <Select defaultValue={[adeudosPorPagar[0]]} isMulti name="porpagar" options={adeudosPorPagar} className="basic-multi-select" classNamePrefix="select" onChange={(item) => ConceptoDePago(item)} required />
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Concepto</label>
                    <textarea id="message" rows="4" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" value={concepto} onChange={({ target: { value } }) => setConcepto(value)} readOnly></textarea>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Monto</label>
                    <input className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " type='number' onChange={({ target: { value } }) => setMonto(value)} required />
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Medio</label>
                    <Select defaultValue={[medios[0]]} options={medios} key={medios.value} onChange={handleChangeMedio} required/>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Observaciones</label>
                    <textarea id="message" rows="4" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" value={observaciones} onChange={({ target: { value } }) => setObservaciones(value)}></textarea>
                    <button className=" flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 my-2" type="submit">Registrar</button>
                    <MensajeError msgError={msgError} /> 
                </div>
      </form>
        </>
    )
}

export default RegistrarPago;