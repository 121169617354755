import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter, Routes, Route, useNavigate, Switch } from "react-router-dom";
import "./css/index.css";
import "./css/output.css";
 
import Recibos from "./modulos/Recibos/Recibos";
import Home from './modulos/Home/home';
import Logout from "./modulos/dasboard/Logout";
import LayoutHome from "./modulos/dasboard/LayoutHome";
import Login from "./modulos/login/login";
import Gastos from "./modulos/Gastos/Gastos";
import Adeudos from "./modulos/Adeudos/Adeudos";
import RegistrarGasto from "./modulos/Gastos/RegistrarGasto";
import RegistrarPago from "./modulos/Recibos/RegistrarPago";
import NotFoundPage from "./modulos/dasboard/NotFoundPage";
import Clasificados from "./modulos/Clasificados/Clasificados";

export default function App() {
 
  return (
    
      <Routes>
        
        <Route  path="/" element={<LayoutHome />} >
        <Route exact path="salir" element={<Logout />} />
        <Route exact path='update' render={() => <useNavigate to='/recibos' render />} />
        <Route exact path='recibos' element={<Recibos />} />
        <Route exact path='adeudos' element={<Adeudos />} />
        <Route exact path='gastos' element={<Gastos />} />
        <Route exact path='registrarPago' element={<RegistrarPago />} />
        <Route exact path='registrarGasto' element={<RegistrarGasto />} />
        <Route exact path='login' element={<Login />} />          
        <Route exact path="recibos" element={<Recibos />} />
        <Route exact path="adeudos" element={<Adeudos />} />
        <Route exact path="gastos" element={<Gastos />} />
        <Route exact path="registrarPago" element={<RegistrarPago />} />
        <Route exact path="registrarGasto" element={<RegistrarGasto />} />
        <Route exact path="clasificados" element={<Clasificados />} />
        <Route exact path="login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} />
        </Route>
 
      </Routes>
   
  );
}
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HashRouter>
      <App/>
    </HashRouter  >
  </React.StrictMode>
);

 

 