import React from 'react';  
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import Cargador from '../dasboard/Cargador'
import {formatoMexico, formatearFecha} from '../helpers/tools';
import { useNavigate } from "react-router-dom";

const Gastos=()=>{

    const [token, setToken]=useState(sessionStorage.getItem('_token'));    
    const [MiUsuario, setMiUsuario]=useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
    const [loading,setLoading]=useState(false);
    const [gastos, setGastos]=useState([]);
    const [fechaInicial, SetFechaInicial]=useState("");
    const [fechaFinal, SetFechaFinal]=useState("");
         
    const navigate = useNavigate();
    
    const consultarGastos=async()=>{        
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "op":"consulta",                
                "fechaInicial":fechaInicial,
                "fechaFinal":fechaFinal
            }     
            let valores=[]       
            setLoading(true)     
               await clienteAxios.post(`api/egresos/`, datos, config)
                .then(res=>{

                    if(res.data.estatus==401){
                        navigate('/salir');     
                        window.location.reload();
                    }

                    res.data.data.forEach(element => {                   
                       const {idegreso, fecha, monto, concepto, notas, nombre, recibos, createdAt, updatedAt}=element
                       let dato={idegreso, fecha, monto, concepto, notas, nombre, recibos, createdAt, updatedAt}            
                        valores=[...valores, dato]                 
                    });                         
                   
                   
                    setLoading(false) 
                }).catch(err=>{
                    setLoading(false) 
                    console.log(err)
                });                                             
                setGastos(valores)                
        }
    }

    useEffect(()=>{                                                
        consultarGastos();    
    },[]);

      return (
        <>
        {loading && <Cargador/>}
        <h1 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900' >Gastos </h1>
       
        {gastos.map((dato, index)=>(      
          <div key={index} className='bg-slate-300 shadow-md w-full flex '>                                                
              <div className='p-2 md:m-3 w-full'>          
                    <p className='font-bold uppercase w-full text-sm p-2 m-1'>No. {' '}<span className='font-normal'>{dato.idegreso}</span></p>              
                    <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Fecha {' '}<span className='font-normal'>{formatearFecha(dato.fecha)}</span></p>            
                    <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>concepto {' '}<span className='font-normal me-10 p-2'>{dato.concepto}</span></p>                                                                  
                    <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Monto {' '}<span className='font-normal'>${formatoMexico(dato.monto)}.00</span></p>    
                    <p className='font-bold uppercase w-full text-sm bg-white rounded-md p-2 m-1'>Nombre {' '}<span className='font-normal'>{dato.nombre}</span></p>    
                    <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Comentario {' '}<span className='font-normal'>{dato.notas}</span></p>    
                    <p className='font-bold uppercase w-full text-sm p-2 m-1'>Recibos {' '}<span className='font-normal'>{dato.recbos}</span></p>              
              </div>                                                                                                 
          </div>
          ))}
     
        </>
      )
    }

export default Gastos;