import React from 'react';  
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import QRCode from "react-qr-code";
import Cargador from '../dasboard/Cargador';
import {formatoMexico, formatearFecha} from '../helpers/tools';
import { useNavigate } from "react-router-dom";

const Recibos=()=>{
    const [token, setToken]=useState(sessionStorage.getItem('_token'));    
    const [MiUsuario, setMiUsuario]=useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
    const [loading,setLoading]=useState(false);
    const [recibos, setRecibos]=useState([]);
    const [fechaInicial, SetFechaInicial]=useState("");
    const [fechaFinal, SetFechaFinal]=useState("");

    const navigate = useNavigate();

    useEffect(()=>{                                                
        consultarRecibos();    
    },[]);

    const consultarRecibos=async()=>{        
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "op":"mis_recibos",    
                "casa":MiUsuario.casa,
                "fechaInicial":fechaInicial,
                "fechaFinal":fechaFinal
            }     
               let valores=[]      
               setLoading(true)     
               await clienteAxios.post(`api/recibos/`, datos, config)
                .then(res=>{                                            
                    if(res.status=200){

                        if(res.data.estatus==401){
                            navigate('/salir')
                            window.location.reload();
                        }

                        if(res.data.data.length===0){
                            // setShowrecibos(false)
                            // setShowGastos(false)
                        }
                        res.data.data.forEach(element => {                   
                        const {recibo, numero, fecha, mes, casa, concepto, monto, medio, observaciones, createdAt, updatedAt}=element
                        let dato={recibo, numero, fecha, mes, casa,concepto, monto, medio, observaciones, createdAt, updatedAt}            
                            valores=[...valores, dato]                 
                        });                                       
                        
                        // setShowrecibos(true)
                        setLoading(false)                         
                    }
                }).catch(err=>{
                    console.log(err)
                    setLoading(false)   
                });                                             
                setRecibos(valores)
        }
    }    
    return (
        <>
        {loading && <Cargador/>}
        <h1 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 my-2'>Recibos de Cuota de Mantenimiento</h1>
        {recibos && recibos.map((dato, index)=>(      
        <div key={index} className='bg-white m-3 p-5 shadow-md  w-full flex '>               
            <div  className=' w-full md:flex bg-blue-50 p-3'>
                  <div className='w-1/5'>
                    <QRCode
                    size={256}
                    style={{ height: "100", maxWidth: "100%", width: "100%" }}
                    value={dato.recibo}
                    viewBox={`0 0 256 256`}
                  />
                  </div>
                  <div className='md:w-4/5 px-2 '>
                    
                    <p className='font-bold uppercase w-full text-sm'>Recibo {' '}<span className='font-normal me-10 p-2'>{dato.numero}</span></p>            
                    <p className='font-bold uppercase w-full text-sm bg-white'><span className=''>Casa {' '} <span className='font-normal'>{dato.casa}</span></span></p>    
                    <p className='font-bold uppercase w-full text-sm'>Fecha {' '}<span className='font-normal'>{formatearFecha(dato.fecha)}</span></p>                           
                    <p className='font-bold uppercase w-full text-sm bg-white'>Concepto {' '}<span className='font-normal'>{dato.concepto}</span></p>    
                    <p className='font-bold uppercase w-full text-sm'>Monto {' '}<span className='font-normal'>${formatoMexico(dato.monto)}.00</span></p>    
                    <p className='font-bold uppercase w-full text-sm bg-white'>Medio {' '}<span className='font-normal'>{dato.medio}</span></p>    
                    <p className='font-bold uppercase w-full text-sm '>Observaciones {' '}<span className='font-normal'>{dato.observaciones}</span></p>    
                    <p className='font-bold uppercase w-full text-sm bg-white'>Folio {' '}<span className='font-normal'>{dato.recibo}</span></p>              
                  </div>                                                                                       
            </div>
        </div>
        ))}
        </>
      )
}

export default Recibos;