'use client'
import { Fragment } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axiox';
import { useNavigate } from "react-router-dom";
import Cargador from '../dasboard/Cargador';

 
 
const  Clasificados=()=> {
  const [token, setToken]=useState(sessionStorage.getItem('_token'));    
  const [MiUsuario, setMiUsuario]=useState(JSON.parse(sessionStorage.getItem('_MiUsuario')));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [loading,setLoading]=useState(false);
  const [clasificados, setClasificados]=useState([]);
  const [servicios, setServicios]=useState([]);
  const [intercambios, setIntercambios]=useState([]);
  const [ventas, setVentas]=useState([]);
  const [navigation, setNavigation ]=useState([]);
  const [clasificaciones, setClasificaciones ]=useState([]);
  const navigate = useNavigate();

  


  const consultarClasificados=async()=>{        
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }

    if(token){
        const datos = {
            "sp":"clasificados",
            "op":"clasificados_listar",                
            "idusuario": 8,
            "idclasificacion": 1,
            "nombre": "Prueba de postman99",
            "descripcion": "Black",
            "imagen": "https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-08.jpg",
            "precio": "165.00",
            "estatus": 1,
            "prime": 1,
        }     
              
           setLoading(true)     
           await clienteAxios.post(`api/sp`, datos, config)
            .then(res=>{   
              let valores=[];                                         
              let ventas=[];                                         
              let intercambios=[];                                         
              let servicios=[];                                         
              let clasificaciones=[];                                         
                if(res.status=200){
                    if(res.data.estatus==401){
                        navigate('/salir')
                        window.location.reload();
                    }            
                        
                   console.info(res.data.data[0])
                    res.data.data[0].forEach(element => {                   
                    const {idClasificado, idusuario, idclasificacion, nombre, descripcion, imagen, precio, estatus, prime, createAd}=element
                    let dato={idClasificado, idusuario, idclasificacion, nombre, descripcion, imagen, precio, estatus, prime, createAd}            

                    if(idclasificacion==1){//ventas
                      ventas=[...ventas, dato]                 
                    } 
                    if(idclasificacion==2){//intercambios
                      intercambios=[...intercambios, dato]                 
                    } 
                    if(idclasificacion==3){//servicios
                      servicios=[...servicios, dato]                 
                    } 
                        valores=[...valores, dato]                 
                    });  
                    res.data.data[1].forEach(element => {                   
                      const { idClasificacion, clasificacion}=element
                      let dato={ idClasificacion, clasificacion}                                                     
                          clasificaciones=[...clasificaciones, dato]                 
                      }); 
                                   
                    setClasificaciones(clasificaciones)                    
                    setClasificados(valores)                    
                    setLoading(false)    
                    console.info("clasificaciones")                      
                    console.info(clasificaciones)                      
                    console.info("clasificados")                      
                    console.info(clasificados)                      
                    console.info("ventas")                      
                    console.info(ventas)      
                    console.info("SERVICIOS")                      
                    console.info(servicios)                      
                    console.info("Intercambios")                      
                    console.info(intercambios)                      
                }
            }).catch(err=>{
                console.log(err)
                setLoading(false)   
            });                                             
            // setClasificados(valores)
    }
}  

useEffect(()=>{                                                
  consultarClasificados();    

  setNavigation([{
    categories: [
      {
        name: 'Ventas',
        featured: ventas
      },
      {
        name: 'Intercambia',
        featured:  intercambios
      },
      {
        name: 'Servicios',
        featured: servicios,
      },
    ],
    pages: [
      { name: 'Inicio', href: '#/clasificados' }    
    ],
  }])
},[]);
  return (
    <div className="bg-gray-50">
        {loading && <Cargador/>}   
      <div>
        {/* Mobile filter dialog */}
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-30 sm:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-30 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>               
            </DialogPanel>
          </div>
        </Dialog>

        <main>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="py-24 text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">Compra, Vende, Intercambia, Ofrece Productos y Servicios en tu Condominio</h1>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                Una forma más segura de hacer negocios con la gente de tu alrededor
              </p>
            </div>
             
            
            {/* Product grid */}
            <section aria-labelledby="products-heading" className="mt-8">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                {clasificados.map((product) => (
                  <span key={product.idClasificado} className="group">
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg sm:aspect-h-3 sm:aspect-w-2">
                      <img
                        alt={product.nombre}
                        src={product.imagen}
                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                    <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
                      <h3>{product.nombre}</h3>
                      <p>{product.precio}</p>                      
                    </div>
                    <p className="mt-1 text-sm italic text-gray-500">{product.descripcion}</p>
                  </span>
                ))}
              </div>
            </section>       
          
          </div>
        </main>       
      </div>
    </div>
  )
}
export default Clasificados;