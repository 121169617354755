import { useEffect, useState } from 'react';

const Password=({
    id, requerido=false, disabled=false, type="password", etiqueta="Clave de acceso", placeholder="", setValue, valor
})=>{
   
    return(
        <>
            <div className="w-full p-2">
            <label className=" block text-sm font-medium leading-6 text-gray-900-sm">{etiqueta}</label>
            <input 
            id={id} 
            type={type}
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" 
            value={valor} 
            required={requerido}
            disabled={disabled}
            placeholder={placeholder}          
            onChange={({target:{value}})=>setValue(value)}
            />
        </div>
        </>
    )
}
export default Password;