import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const Logout=()=>{
                
 const navigate = useNavigate();
                   
  useEffect(()=>{  
    sessionStorage.clear();         
    localStorage.clear();
    navigate('/login')
    window.location.reload();
    // window.location.href = '/login';
  }, [])
    return(
      <>
      Gracias por tu visita!!
      </>
    )
}

export default Logout;