import React from 'react';  
import clienteAxios from "../../config/axiox";
import { useState, useEffect } from "react";
import MensajeOK from "../componentes/MensajeOk";
import MensajeError from "../componentes/MensajeError";
import CargaDocumento from '../Documentos/CargaDocumento';
const RegistrarGasto = () => {
  
  const [token, setToken] = useState(sessionStorage.getItem("_token"));
  const [MiUsuario, setMiUsuario] = useState(JSON.parse(sessionStorage.getItem("_MiUsuario")));
  const [loading, setLoading] = useState(false);

  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [monto, setMonto] = useState(0);
  const [nombre, setNombre] = useState("");
  const [recibo, setRecibo] = useState("");
  const [notas, setNotas] = useState("");
  const [msgError, setMesgError]=useState("");
  const [modalFile, setModalFile]=useState(false); 


  const msgOk = "Se registró correctamente el gasto con el folio: " + recibo;

  const limpiar = () => {
    setFecha("");
    setConcepto("");
    setMonto(0);
    setNombre("");
    setRecibo("");
    setNotas("");
  };
 

  const RegistraGasto = async (event) => {
    event.preventDefault();

    if( [fecha, concepto, monto, nombre].includes('')){
      setMesgError("Debe ingresar todos los datos.");
      return;
    }


    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (token) {
      // recibo, fecha, mes, casa, concepto, monto, medio, observaciones,
      const datos = {
        op: "registro",
        fecha: fecha,
        concepto: concepto,
        monto: monto,
        nombre: nombre,
        notas: notas,
      };

      await clienteAxios.post(`api/egresos/`, datos, config).then((res) => {        
        console.info(res.data.data[0].recibos)
        setRecibo(res.data.data[0].recibos);
        const interval = setInterval(() => {
          limpiar();
        }, 3000);
      });
    }
  };

  return (
    <>
      <form onSubmit={RegistraGasto}>
        <div className="div-control p-3">
          {!recibo == "" && <MensajeOK msgOk={msgOk} />}
          <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 my-2">
            Registro de Gasto  
          </h1>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Fecha{" "}
          </label>
          <input
            type="date"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
            value={fecha}
            onChange={({ target: { value } }) => setFecha(value)}
          />
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Monto
          </label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
            type="number"
            value={monto}
            onChange={({ target: { value } }) => setMonto(value)}
          />
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Concepto
          </label>
          <textarea
            id="message"
            rows="4"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder=""
            value={concepto}
            onChange={({ target: { value } }) => setConcepto(value)}
          ></textarea>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Nombre
          </label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
            type="text"
            value={nombre}
            onChange={({ target: { value } }) => setNombre(value)}
          />
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Nota
          </label>
          <textarea
            id="message"
            rows="4"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder=""
            value={notas}
            onChange={({ target: { value } }) => setNotas(value)}
          ></textarea>
          <button
            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 my-2"
            type="submit"
          >
            Registrar
          </button>

          <MensajeError msgError={msgError} /> 

        </div>


        </form>
      {/* <button 
      className="flex w-1/3 justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 my-2"
      onClick={()=>setModalFile(true)}
      >
            Cargar Documento
      </button>
      {modalFile &&  <CargaDocumento title={"Documento"} setModalFile={setModalFile}/>} */}
    </>
  );
};

export default RegistrarGasto;
